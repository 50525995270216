<template>
    <div v-loading="$wait.is('loading.services_types')">
        <div class="flex justify-between border-b border-solid border-gray-200 pb-2 mb-5">
            <h1>{{ $t('clients.approaching_services') }}</h1>
        </div>
        <div v-if="servicesData.length" class="space-y-4">
            <div v-for="event in servicesData" :key="event.id" class="border rounded-lg text-sm">
                <div class="flex items-center justify-between py-2 px-3 bg-blue-50 rounded-t-lg">
                    <p class="flex">
                        <span class="inline-block mr-2 w-5 h-5 rounded-md bg-blue-400" />
                        {{ event.date }} ({{ getDaysUntilNow(event.date, event.start_time) }})
                    </p>
                    <p>{{ event.start_time }} - {{ event.end_time }}</p>
                </div>
                <div class="mt-3 text-center">
                    <p>{{ event.street }}, {{ event.post_code }} {{ event.city }}</p>
                </div>
                <div class="flex justify-center">
                    <div class="flex -space-x-8 p-4 ml-8">
                        <template v-for="employee in event.employees">
                            <img
                                v-if="employee.avatar_src"
                                :key="employee.id"
                                class="w-20 h-20 ring-4 ring-white bg-white rounded-full object-cover"
                                :src="employee.avatar_src || '@/assets/images/unisex_user.png'"
                            >
                            <img
                                v-else
                                :key="employee.id"
                                class="w-20 h-20 ring-4 ring-gray-200 bg-white rounded-full object-cover"
                                src="@/assets/images/unisex_user.png"
                            >
                        </template>
                    </div>
                    <div class="flex flex-col justify-center">
                        <p v-for="employee in event.employees" :key="employee.id">
                            {{ employee.name }} {{ employee.surname }}
                        </p>
                    </div>
                </div>
                <div class="flex justify-around bg-blue-50 p-2 text-center rounded-b-lg">
                    <p v-for="service in event.services" :key="service.id">
                        {{ service.name }}
                    </p>
                </div>
            </div>
        </div>
        <div v-else class="flex flex-col items-center">
            <img src="@/assets/images/undraw_empty_result.svg" class="my-6" alt="" width="150">
            <p>
                {{ $t('common.no_future_services') }}
            </p>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        servicesData: {
            type:    Array,
            default: () => [],
        },
    },

    methods: {
        getDaysUntilNow(date, time) {
            return this.$dayjs(`${date} ${time}`, 'DD.MM.YYYY HH:mm').fromNow();
        },
    },
};
</script>
